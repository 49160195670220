import { Injectable } from '@angular/core';
import { ProductStateService } from '../state/product-state.service';
import { ServicesModule } from './services.module';
import { buttonOptions, Product } from '@mri-platform/ag-shared/services';
import { EntityList } from '@mri-platform/shared/entity';

@Injectable({
  providedIn: ServicesModule
})
export class ProductService {
  constructor(private productStateService: ProductStateService) {}

  getAddAppOptions() {
    return this.productStateService.addOptions$;
  }

  getPortalsProduct() {
    return this.productStateService.portalsProduct$;
  }

  getSelfHostedApplications() {
    return this.productStateService.selfHostedApplications$;
  }

  getPartnerProducts() {
    return this.productStateService.partnerProduct$;
  }

  setAddAppOptions(addOptions: buttonOptions[]) {
    this.productStateService.setAddAppOptions(addOptions);
  }

  setSelfHostedApplications(apps: Product[]) {
    this.productStateService.setSelfHostedApplications(apps);
  }

  setSelfHosted(selfHosted: EntityList<Product>) {
    this.productStateService.setSelfHosted(selfHosted);
  }

  getProducts() {
    return this.productStateService.products$;
  }
}
