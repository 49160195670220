import { Injectable } from '@angular/core';
import { AdminApplicationApiService, ClientService, ClientSettings } from '@mri-platform/ag-shared/services';
import { BehaviorSubject, filter, map, switchMap } from 'rxjs';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class ClientStateService {
  private clientSettingsSubject = new BehaviorSubject<ClientSettings[]>([]);
  clientSettings$ = this.clientSettingsSubject.asObservable();

  private clientHeaderSubject = new BehaviorSubject<string>('Client settings');
  clientHeader$ = this.clientHeaderSubject.asObservable();

  constructor(
    private adminApplicationApiService: AdminApplicationApiService,
    private clientService: ClientService
  ) {
    this.adminApplicationApiService.getClientSettings().subscribe(clientSettings => {
      this.setClientSettings(clientSettings);
    });

    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.adminApplicationApiService.getClientSettings()),
        map(clientSettings => this.setClientSettings(clientSettings))
      )
      .subscribe();
  }

  setClientSettings(logoSource: ClientSettings[]): void {
    this.clientSettingsSubject.next(logoSource);
  }

  setClientHeader(header: string): void {
    this.clientHeaderSubject.next(header);
  }
}
