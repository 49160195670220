import { Injectable } from '@angular/core';
import {
  AdminApplicationApiService,
  ApplicationDetail,
  ApplicationUser,
  ClientService,
  createUserApplicationDetail,
  getApplicationUser,
  sortAdminApplication,
  sortUsers,
  User,
  UserApiService,
  UserApplicationDetail
} from '@mri-platform/ag-shared/services';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { Dictionary } from 'lodash';
import { combineLatest, filter, map, switchMap, tap } from 'rxjs';
import { UserApplicationDetailStateService } from '../state/user-application-detail-state.service';
import { CentralIdentityApiService } from './central-identity-api.service';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class UserApplicationDetailService {
  constructor(
    private adminApplicationApiService: AdminApplicationApiService,
    private userApiService: UserApiService,
    private userApplicationDetailStateService: UserApplicationDetailStateService,
    private errorPolicy: ErrorPolicyService,
    private clientService: ClientService,
    private centralIdentityService: CentralIdentityApiService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.loadUsers()),
        tap(userApplicationDetail => {
          this.setUsers(userApplicationDetail);
          this.setIsLoaded(true);
        })
      )
      .subscribe();

    this.getUserToUpdate().subscribe(user => {
      this.userApplicationDetailStateService.updatedUser = user;
    });
    this.getApplicationsToUpdate().subscribe(apps => {
      this.userApplicationDetailStateService.updatedApplications = apps;
    });
  }

  getUserFromIdp(userId: string) {
    return this.centralIdentityService.getUserFromIdp(userId);
  }

  getUserMfaFactors(userId: string) {
    return this.centralIdentityService.getUserMfaFactors(userId);
  }

  resetUserMfaFactors(userId: string) {
    return this.centralIdentityService.resetUserMfaFactors(userId);
  }

  getApplicationsToUpdate() {
    return this.userApplicationDetailStateService.onApplicationsToUpdateChanged;
  }

  setApplicationsToUpdate(apps: ApplicationDetail[]) {
    this.userApplicationDetailStateService.applicationsToUpdate.next(apps);
  }

  getUserToUpdate() {
    return this.userApplicationDetailStateService.onUserToUpdateChanged;
  }

  setUserToUpdate(user: User) {
    this.userApplicationDetailStateService.userToUpdate.next(user);
  }

  getIsLoaded() {
    return this.userApplicationDetailStateService.onIsLoadedChanged;
  }

  setIsLoaded(isLoaded: boolean) {
    this.userApplicationDetailStateService.isLoaded.next(isLoaded);
  }

  getIsSaving() {
    return this.userApplicationDetailStateService.onIsSavingChanged;
  }

  setIsSaving(isSaving: boolean) {
    this.userApplicationDetailStateService.isSaving.next(isSaving);
  }

  getIsCancelled() {
    return this.userApplicationDetailStateService.onCancelledChanged;
  }

  setIsCancelled(isCancelled: boolean) {
    if (isCancelled) {
      this.userApplicationDetailStateService.updatedUser = undefined;
      this.userApplicationDetailStateService.updatedApplications = [];
    }
    this.userApplicationDetailStateService.isCancelled.next(isCancelled);
  }

  getAutoSelectedUser() {
    return this.userApplicationDetailStateService.userList[0];
  }

  getUser(id: string) {
    return this.userApplicationDetailStateService.userList.find(user => user.id === id);
  }

  getUsers() {
    return this.userApplicationDetailStateService.users$;
  }

  setUsers(users: UserApplicationDetail[]) {
    this.userApplicationDetailStateService.userList = users;
    this.userApplicationDetailStateService.setUsers(users);
  }

  getSelectedUser() {
    return this.userApplicationDetailStateService.onSelectedUserChanged;
  }

  setSelectedUser(user: UserApplicationDetail) {
    this.userApplicationDetailStateService.updatedUser = undefined;
    this.userApplicationDetailStateService.updatedApplications = [];
    this.userApplicationDetailStateService.selectedUser.next(user);
  }

  private saveUser() {
    const updatedUser = this.userApplicationDetailStateService.updatedUser;
    if (updatedUser) {
      this.setIsSaving(true);
      this.userApiService
        .updateUser(updatedUser)
        .pipe(this.errorPolicy.catchHandle())
        .subscribe(() => {
          this.setIsSaving(false);
          const usersList = this.userApplicationDetailStateService.userList;
          const index = usersList.findIndex(x => x.id === updatedUser.id);
          usersList[index].setUserDetail(updatedUser);
          this.setUsers(usersList);
          this.setSelectedUser(usersList[index]);
        });
    }
  }

  private saveApplications() {
    let updatedApplications: ApplicationUser[] = [];
    const updatedApplicationList = this.userApplicationDetailStateService.updatedApplications;
    if (updatedApplicationList) {
      updatedApplications = updatedApplicationList.map(x => {
        return getApplicationUser(x);
      });
    }
    if (updatedApplications.length > 0) {
      this.setIsSaving(true);
      this.adminApplicationApiService
        .updateApplicationUsers(updatedApplications)
        .pipe(this.errorPolicy.catchHandle())
        .subscribe(() => {
          this.setIsSaving(false);
          const usersList = this.userApplicationDetailStateService.userList;
          const userId = updatedApplications[0].userId;
          const index = usersList.findIndex(x => x.id === userId);
          usersList[index].updateApplications(updatedApplications);
          this.setUsers(usersList);
          this.setSelectedUser(usersList[index]);
        });
    }
  }

  private saveUserAndApplications() {
    const updatedUser = this.userApplicationDetailStateService.updatedUser;
    const updatedApplicationsList = this.userApplicationDetailStateService.updatedApplications;
    let updatedApplications: ApplicationUser[] = [];
    if (updatedApplicationsList) {
      updatedApplications = updatedApplicationsList.map(x => {
        return getApplicationUser(x);
      });
    }

    if (updatedUser && updatedApplications.length > 0) {
      this.setIsSaving(true);
      combineLatest([
        this.userApiService.updateUser(updatedUser),
        this.adminApplicationApiService.updateApplicationUsers(updatedApplications)
      ])
        .pipe(this.errorPolicy.catchHandle())
        .subscribe(() => {
          this.setIsSaving(false);
          const usersList = this.userApplicationDetailStateService.userList;
          const userIndex = usersList.findIndex(x => x.id === updatedUser.id);
          usersList[userIndex].setUserDetail(updatedUser);
          usersList[userIndex].updateApplications(updatedApplications);
          this.setUsers(usersList);
          this.setSelectedUser(usersList[userIndex]);
        });
    } else if (updatedUser) {
      this.saveUser();
    } else if (updatedApplications.length > 0) {
      this.saveApplications();
    }
  }

  save() {
    this.saveUserAndApplications();
  }

  private loadUsers() {
    return combineLatest([
      this.userApiService.getUsers(),
      this.adminApplicationApiService.getApplicationUsers(),
      this.adminApplicationApiService.getApplications()
    ]).pipe(
      map(([users, applicationUsers, applications]) => {
        const sortedApplications = applications.sort((a, b) => sortAdminApplication(a, b));
        const usersSorted = users.sort((a, b) => sortUsers(a, b));
        const userApplicationDetail: UserApplicationDetail[] = usersSorted.map(user => {
          const appUserMap = applicationUsers
            .filter(x => x.userId === user.id)
            .reduce((map, applicationUser) => {
              map[applicationUser.applicationPkId] = applicationUser;
              return map;
            }, {} as Dictionary<ApplicationUser>);

          return createUserApplicationDetail(user, sortedApplications, appUserMap);
        });
        return userApplicationDetail;
      })
    );
  }
}
