import { Injectable } from '@angular/core';
import { AdminApplication, UserApplicationsVisibility } from '@mri-platform/ag-shared/services';
import { ReplaySubject } from 'rxjs';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class UserApplicationsMatrixStateService {
  userApplicationsVisibility = new ReplaySubject<UserApplicationsVisibility[]>();
  userApplicationsVisibilityList: UserApplicationsVisibility[] = [];
  updatedUserApplicationVisibilty: UserApplicationsVisibility[] = [];
  userApplicationsVisibilityToUpdate = new ReplaySubject<UserApplicationsVisibility[]>();
  onUserApplicationsVisibilityToUpdateChanged = this.userApplicationsVisibilityToUpdate.asObservable();
  applications = new ReplaySubject<AdminApplication[]>();
  isSaving = new ReplaySubject<boolean>();
  onIsSavingChanged = this.isSaving.asObservable();
  isCancelled = new ReplaySubject<boolean>();
  onCancelledChanged = this.isCancelled.asObservable();
}
