import { Injectable } from '@angular/core';
import { ClientSettings } from '@mri-platform/ag-shared/services';
import { Observable } from 'rxjs';
import { ClientStateService } from '../state/client-state.service';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class ClientSettingsService {
  constructor(private clientStateService: ClientStateService) {}

  getClientSettings() {
    return this.clientStateService.clientSettings$;
  }

  setClientSettings(settings: ClientSettings[]) {
    this.clientStateService.setClientSettings(settings);
  }

  setClientHeader(header: string) {
    this.clientStateService.setClientHeader(header);
  }

  getClientHeader(): Observable<string> {
    return this.clientStateService.clientHeader$;
  }
}
