import { Injectable } from '@angular/core';
import { ApplicationDetail, User, UserApplicationDetail } from '@mri-platform/ag-shared/services';
import { BehaviorSubject } from 'rxjs';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class UserApplicationDetailStateService {
  private usersSubject = new BehaviorSubject<UserApplicationDetail[] | undefined>(undefined);
  users$ = this.usersSubject.asObservable();

  userToUpdate = new BehaviorSubject<User | undefined>(undefined);
  onUserToUpdateChanged = this.userToUpdate.asObservable();
  updatedUser: User | undefined;

  applicationsToUpdate = new BehaviorSubject<ApplicationDetail[] | undefined>(undefined);
  onApplicationsToUpdateChanged = this.applicationsToUpdate.asObservable();
  updatedApplications: ApplicationDetail[] | undefined = [];

  selectedUser = new BehaviorSubject<UserApplicationDetail | undefined>(undefined);
  onSelectedUserChanged = this.selectedUser.asObservable();
  isCancelled = new BehaviorSubject<boolean | undefined>(undefined);
  onCancelledChanged = this.isCancelled.asObservable();
  isLoaded = new BehaviorSubject<boolean | undefined>(undefined);
  onIsLoadedChanged = this.isLoaded.asObservable();
  userList: UserApplicationDetail[] = [];
  isSaving = new BehaviorSubject<boolean | undefined>(undefined);
  onIsSavingChanged = this.isSaving.asObservable();

  setUsers(user: UserApplicationDetail[]) {
    this.usersSubject.next(user);
  }
}
