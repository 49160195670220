import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  AdminApplication,
  AdminApplicationApiService,
  buttonOptionsType,
  ClientApplicationType,
  ClientService,
  PartnerApplication,
  Product
} from '@mri-platform/ag-shared/services';
import { AvatarIcon } from '@mri-platform/shared/common-types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ButtonProgressState } from '@mri-platform/ui-buttons';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
  tap
} from 'rxjs';
import { ApplicationStateService } from '../state/application-state.service';
import { ProductService } from './product.service';
import { ServicesModule } from './services.module';
import { UserService } from './user.service';

@Injectable({
  providedIn: ServicesModule
})
export class ApplicationService {
  private newDetailsSubject = new Subject<void>();
  newDetails$ = this.newDetailsSubject.asObservable();

  private newAppSubject = new BehaviorSubject<AdminApplication>({} as AdminApplication);
  newApp$ = this.newAppSubject.asObservable();

  private gridSubject = new Subject<void>();
  grid$ = this.gridSubject.asObservable();

  private selfHostedProducts: Product[] = [];
  private partnerProducts: Product[] = [];
  private products: Product[] = [];
  private portalProducts: Product[] = [];

  constructor(
    private applicationStateService: ApplicationStateService,
    private location: Location,
    private adminApplicationApiService: AdminApplicationApiService,
    private productService: ProductService,
    private userService: UserService,
    private clientService: ClientService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.productService.getProducts()),
        tap(products => (this.products = products)),
        switchMap(() => this.productService.getSelfHostedApplications()),
        tap(products => (this.selfHostedProducts = products)),
        switchMap(() => this.productService.getPartnerProducts()),
        tap(partnerProducts => (this.partnerProducts = partnerProducts)),
        switchMap(() => this.productService.getPortalsProduct()),
        tap(portalProducts => (this.portalProducts = portalProducts))
      )
      .subscribe();
  }

  createNewClientDefinedApp() {
    this.setSelectAdminApplication({
      iconBackgroundColor: '#778692',
      offByAdmin: true
    } as AdminApplication);
    this.setPageTitle('Add client-defined application');
    this.setNameLabel('Name');
    this.setDescriptionLabel('Description');
    this.setIsEditable(true);
    this.setIsNew(true);
    this.setShowDelete(false);
    this.setIsPortal(false);
    this.setIsPartner(false);
    this.setCanCancel(true);
    this.setShowProductDropdown(false);
    this.applicationStateService.clearList();
    this.setIsClientDefined(true);
    this.setIsValid(false);
    this.routeToItem('-1?clientAppType=ClientDefined');
    this.setIconValid(false);
    this.setShowSecurityManagementUrl(false);
    this.userService.setSelectedApplicationChange();
    this.userService.setTempUserList([]);
    this.setShowDefaultAppField(true);
  }

  createNewPortalApp() {
    this.setSelectAdminApplication({
      iconBackgroundColor: '#778692',
      offByAdmin: true
    } as AdminApplication);
    this.setPageTitle('Add portal application');
    this.setIsEditable(true);
    this.setIsNew(true);
    this.setShowDelete(false);
    this.setIsPortal(true);
    this.setIsPartner(false);
    this.setCanCancel(true);
    this.setShowProductDropdown(true);
    this.applicationStateService.clearList();
    this.setNameLabel('Portal Type');
    this.setDescriptionLabel('Portal Name');
    this.setClientAppType('Portal');
    this.setClientAppType('ClientDefined');
    this.setIsClientDefined(false);
    this.setIsValid(true);
    this.setIsProduct(true);
    this.routeToItem('-1?clientAppType=Portal');
    this.setShowSecurityManagementUrl(true);
    this.userService.setSelectedApplicationChange();
    this.userService.setTempUserList([]);
    this.setShowDefaultAppField(true);
  }

  // Question for review: Seems like these create methods never get called?
  createNewSelfHostedApp() {
    this.setSelectAdminApplication({
      iconBackgroundColor: '#778692',
      offByAdmin: true
    } as AdminApplication);
    this.setPageTitle('Add self-hosted application');
    this.setIsEditable(true);
    this.setIsNew(true);
    this.setShowDelete(false);
    this.setIsPortal(false);
    this.setIsPartner(false);
    this.setCanCancel(true);
    this.setNameLabel('Application');
    this.setDescriptionLabel('Description');
    this.setClientAppType('SelfHosted');
    this.setIsSelfHosted(true);
    this.setShowProductDropdown(true);
    this.applicationStateService.clearList();
    this.setIsClientDefined(false);
    this.setIsValid(true);
    this.setIsProduct(true);
    this.routeToItem('-1?clientAppType=SelfHosted');
    this.setShowSecurityManagementUrl(false);
    this.setShowDefaultAppField(false);
    this.userService.setSelectedApplicationChange();
    this.userService.setTempUserList([]);
  }

  createNewPartnerApp(id: number, clientAppType: ClientApplicationType) {
    this.productService.getPartnerProducts().subscribe(products => {
      const product = products.find(p => p.id === id);
      if (product) {
        this.setSelectedProduct(product);
        this.setIsProduct(false);
        this.setIsNew(true);
        this.setShowDelete(false);
        this.setCanCancel(true);
        this.setIsEditable(true);
        this.setIsPartner(true);
        this.setIsEditable(true);
        this.setIsDirty(true);
        this.setShowProductDropdown(false);
        this.applicationStateService.clearList();
        this.location.replaceState(`/ag-admin-applications/-1?clientAppType=${clientAppType}&productId=${id}`);
        this.setShowSecurityManagementUrl(true);
        this.userService.setSelectedApplicationChange();
        this.userService.setTempUserList([]);
        this.setShowDefaultAppField(true);
      }
    });
  }

  navigateToFirstApp() {
    const subscription: Subscription = this.applicationStateService.currentApplications$
      .pipe(
        filter(apps => apps.length > 0),
        take(1),
        tap(apps => {
          this.setSelectedAdminApplication(apps[0]);
          this.setSelectedAdminApplicationId(apps[0].applicationPkId);
          this.setIsNew(false);
          this.setShowDelete(true);
          this.setCanDelete(true);
          this.routeToItem(apps[0].applicationPkId.toString());
          this.userService.setSelectedApplicationChange();
          this.userService.setTempUserList([]);
          this.determineView(apps[0]);
        })
      )
      .subscribe();

    subscription.add(() => subscription.unsubscribe());
  }

  determineView(item: AdminApplication) {
    this.setShowDefaultAppField(true);
    this.setPageTitle(item.applicationName);
    if (item.productId) {
      this.setShowProductDropdown(true);
      const selfHostedApps = this.selfHostedProducts;
      const partnerProducts = this.partnerProducts;
      const products = this.products;
      const portalProducts = this.portalProducts;
      const filteredProducts = products.filter(product => !portalProducts.some(portal => portal.id === product.id));

      if (selfHostedApps.some(app => app.id === item.productId)) {
        this.setIsSelfHosted(true);
        this.setNameLabel('Application');
        this.setDescriptionLabel('Description');
        this.setIsPortal(false);
        this.setIsEditable(true);
        this.setIsPartner(false);
        this.setShowSecurityManagementUrl(false);
        this.setShowDefaultAppField(false);
      } else if (
        partnerProducts.some(product => product.id === item.productId) ||
        filteredProducts.some(product => product.id === item.productId)
      ) {
        this.setShowProductDropdown(false);
        this.setIsPartner(true);
        this.setNameLabel('Name');
        this.setDescriptionLabel('Description');
        this.setIsClientDefined(false);
        this.setIsEditable(false);
      } else if (portalProducts.some(product => product.id === item.productId)) {
        this.setIsSelfHosted(false);
        this.setIsPortal(true);
        this.setIsPartner(false);
        this.setNameLabel('Portal Type');
        this.setDescriptionLabel('Portal Name');
        this.setIsClientDefined(false);
      } else {
        this.setShowProductDropdown(false);
        this.setNameLabel('Name');
        this.setDescriptionLabel('Description');
        this.setIsEditable(true);
        this.setIsPartner(false);
        this.setShowSecurityManagementUrl(true);
      }
    } else {
      this.setShowProductDropdown(false);
      this.setNameLabel('Name');
      this.setDescriptionLabel('Description');
      this.setIsEditable(true);
      this.setIsPartner(false);
      this.setShowSecurityManagementUrl(true);
    }

    if (item.applicationOwnerType === 'Official') {
      this.setShowProductDropdown(false);
      this.setIsPartner(true);
      return;
    }
  }

  routeToItem(id: string, queryParams?: Params) {
    this.location.replaceState(`/admin/ag-admin-applications/${id}`, '', queryParams);
  }

  setSelectedAdminApplication(value: AdminApplication) {
    this.applicationStateService.setSelectedAdminApplication(value);
  }

  setNewDetails() {
    this.newDetailsSubject.next();
  }

  getNewDetails() {
    return this.newDetails$;
  }

  setGrid() {
    this.gridSubject.next();
  }

  getGrid() {
    return this.grid$;
  }

  setIsNew(isNew: boolean) {
    this.applicationStateService.setIsNew(isNew);
  }

  setModel(model: AdminApplication) {
    this.applicationStateService.setModel(model);
  }

  setClientAppType(cat: ClientApplicationType | null) {
    this.applicationStateService.setClientAppType(cat);
  }

  setIsSelfHosted(isSelfHosted: boolean) {
    this.applicationStateService.setIsSelfHosted(isSelfHosted);
  }

  setIconSelectorOpen(value: boolean) {
    this.applicationStateService.setIconSelectorOpen(value);
  }

  setIsClientDefined(isClientDefined: boolean) {
    this.applicationStateService.setIsClientDefined(isClientDefined);
  }

  setIsPortal(isPortal: boolean) {
    this.applicationStateService.setIsPortal(isPortal);
  }

  setIsEditable(isEditable: boolean) {
    this.applicationStateService.setIsEditable(isEditable);
  }

  setDescriptionLabel(descriptionLabel: string) {
    this.applicationStateService.setDescriptionLabel(descriptionLabel);
  }

  setNameLabel(nameLabel: string) {
    this.applicationStateService.setNameLabel(nameLabel);
  }

  setIsPartner(isPartner: boolean) {
    this.applicationStateService.setIsPartner(isPartner);
  }

  setShowProductDropdown(showProductDropdown: boolean) {
    this.applicationStateService.setShowProductDropdown(showProductDropdown);
  }

  setPartnerDrawerOpen(isOpen: boolean) {
    this.applicationStateService.setIsPartnerDrawerOpen(isOpen);
  }

  setPageTitle(title: string) {
    this.applicationStateService.setPageTitle(title);
  }

  setSelectedAdminApplicationId(appId: number): void {
    this.applicationStateService.currentApplications$.subscribe(apps => {
      const app = apps.find(a => a.applicationPkId === appId);
      if (app) {
        this.applicationStateService.setSelectedAdminApplication(app);
      } else {
        return;
      }
    });
  }

  setSelectAdminApplication(app: AdminApplication) {
    this.applicationStateService.setSelectedAdminApplication(app);
  }

  setCanCancel(value: boolean) {
    this.applicationStateService.setCanCancel(value);
  }

  setShowSecurityManagementUrl(value: boolean) {
    this.applicationStateService.setShowSecurityManagementUrl(value);
  }

  setShowDefaultAppField(value: boolean) {
    this.applicationStateService.setShowDefaultAppField(value);
  }

  getShowDefaultAppField() {
    return this.applicationStateService.showDefaultAppField$;
  }

  getShowSecurityManagementUrl() {
    return this.applicationStateService.shouldShowSecurityManagementUrl$;
  }

  getApplications(): Observable<AdminApplication[]> {
    return this.applicationStateService.currentApplications$;
  }

  getIsDirty() {
    return this.applicationStateService.isDirty$;
  }

  getIsDirtySubject() {
    return this.applicationStateService.isDirtySubject.value;
  }

  getIsValid() {
    return this.applicationStateService.isValid$;
  }

  getPageTitle() {
    return this.applicationStateService.pageTitle$;
  }

  getIsIconSelectorOpen() {
    return this.applicationStateService.iconSelectorOpen$;
  }

  getIsClientDefined() {
    return this.applicationStateService.isClientDefined$;
  }

  getIsPortal() {
    return this.applicationStateService.isPortal$;
  }

  getIsEditable() {
    return this.applicationStateService.isEditable$;
  }

  getNegatedIsEditable(): Observable<boolean> {
    return this.applicationStateService.isEditable$.pipe(map(isEditable => !isEditable));
  }

  getNegatedIsPortal(): Observable<boolean> {
    return this.applicationStateService.isPortal$.pipe(map(isPortal => !isPortal));
  }

  getNegatedIsNew(): Observable<boolean> {
    return this.applicationStateService.isNew$.pipe(map(isNew => !isNew));
  }

  getDescriptionLabel() {
    return this.applicationStateService.descriptionLabel$;
  }

  getIsPartner() {
    return this.applicationStateService.isPartner$;
  }

  getShowProductDropdown() {
    return this.applicationStateService.showProductDropdown$;
  }

  getNameLabel() {
    return this.applicationStateService.nameLabel$;
  }

  getAppNameLabel() {
    return this.applicationStateService.appNameLabel$;
  }

  getClientAppType() {
    return this.applicationStateService.clientAppType$;
  }

  getIsPartnerDrawerOpen() {
    return this.applicationStateService.isPartnerDrawerOpen$;
  }

  getPartnerApplications() {
    return this.applicationStateService.partnerApplications$;
  }

  getIsNew() {
    return this.applicationStateService.isNew$;
  }

  getIsSelfHosted() {
    return this.applicationStateService.isSelfHosted$;
  }

  getCanCancel() {
    return this.applicationStateService.canCancel$;
  }

  getCanDelete() {
    return this.applicationStateService.canDelete$;
  }

  getCanSave() {
    return this.applicationStateService.canSave$;
  }

  getShowDelete() {
    return this.applicationStateService.showDelete$;
  }

  setShowDelete(showDelete: boolean) {
    this.applicationStateService.setShowDelete(showDelete);
  }

  setCanDelete(canDelete: boolean) {
    this.applicationStateService.setCanDelete(canDelete);
  }

  getDeleteState() {
    return this.applicationStateService.deleteProgressState$;
  }

  getSaveState() {
    return this.applicationStateService.saveProgressState$;
  }

  setSaveState(state: ButtonProgressState) {
    this.applicationStateService.setSaveState(state);
  }

  setDeleteState(state: ButtonProgressState) {
    this.applicationStateService.setDeleteState(state);
  }

  getSelectedAdminApplicationId() {
    return this.applicationStateService.selectedAdminApplicationId$;
  }

  getApplicationById(id: number) {
    return this.applicationStateService.currentApplications$.pipe(
      map(applications => applications.find(app => app.applicationPkId === id))
    );
  }

  getSelectedAdminApplication() {
    return this.applicationStateService.selectedAdminApplication$;
  }

  getCancelClicked() {
    return this.applicationStateService.getCancelClicked();
  }

  getDeleteClicked() {
    return this.applicationStateService.getDeleteClicked();
  }

  setCancelClicked() {
    this.applicationStateService.setCancelClicked();
  }

  setIsDirty(isDirty: boolean) {
    this.applicationStateService.setIsDirty(isDirty);
  }

  setIsValid(isValid: boolean) {
    this.applicationStateService.setIsValid(isValid);
  }

  getSaveClicked() {
    return this.applicationStateService.getSaveClicked();
  }

  setSaveClicked() {
    this.applicationStateService.setSaveClicked();
  }

  getToNewAppDetails() {
    return this.applicationStateService.getToNewAppDetails();
  }

  setToNewAppDetails() {
    this.applicationStateService.setToNewAppDetails();
  }

  //TODO: Make better methods for post and put
  putAdminApplications(): Observable<AdminApplication> {
    return combineLatest([
      this.applicationStateService.avatarIcon$.pipe(take(1)),
      this.applicationStateService.selectedAdminApplication$.pipe(take(1))
    ]).pipe(
      filter(([avatar, app]) => !!avatar && !!app),
      map(([avatar, app]) => {
        if (app) {
          return {
            applicationPkId: app.applicationPkId ?? null,
            applicationDescription: app.applicationDescription,
            applicationName: app.applicationName,
            applicationOwnerType: 'Client',
            iconBackgroundColor: app.iconBackgroundColor ?? avatar.iconBackgroundColor,
            iconFileName: app.iconFileName ?? avatar.iconFileName ?? null,
            iconInitials: app.iconInitials ?? avatar.iconInitials ?? null,
            iconPath: app.iconPath ?? avatar.iconPath ?? null,
            iconSource: app.iconSource ?? avatar.iconSource ?? null,
            offByAdmin: !app.offByAdmin,
            productId: app.productId,
            url: app.url ?? null,
            securityManagementUrl: app.securityManagementUrl ?? null,
            securityManagementUrlTemplate: app.securityManagementUrlTemplate ?? null
          } as AdminApplication;
        }
        throw new Error('No application selected');
      }),
      switchMap(payload => this.adminApplicationApiService.putAdminApplications(payload)),
      switchMap(response =>
        this.applicationStateService.currentApplications$.pipe(
          take(1),
          map(apps => {
            let updatedApps = apps.filter(app => app.applicationPkId !== response.applicationPkId);
            updatedApps = [...updatedApps, response];
            response.offByAdmin = !response.offByAdmin;
            this.applicationStateService.setApplications(
              updatedApps.sort((a, b) => a.applicationName.localeCompare(b.applicationName))
            );
            this.setSelectedAdminApplication(response as AdminApplication);
            this.setSelectedAdminApplicationId(response.applicationPkId);
            this.setIsNew(false);
            this.setPageTitle(response.applicationName);
            this.location.replaceState(`/ag-admin-applications/${response.applicationPkId}`);
            return response;
          })
        )
      )
    );
  }

  //TODO: Make better methods for post and put
  postAdminApplications(): Observable<AdminApplication> {
    return combineLatest([
      this.applicationStateService.avatarIcon$.pipe(take(1)),
      this.applicationStateService.selectedAdminApplication$.pipe(take(1))
    ]).pipe(
      filter(([avatar, app]) => !!avatar && !!app),
      map(([avatar, app]) => {
        if (app) {
          return {
            applicationDescription: app.applicationDescription,
            applicationName: app.applicationName,
            applicationOwnerType: 'Client',
            iconBackgroundColor: app.iconBackgroundColor ?? avatar.iconBackgroundColor,
            iconFileName: app.iconFileName ?? avatar.iconFileName ?? null,
            iconInitials: app.iconInitials ?? avatar.iconInitials ?? null,
            iconPath: app.iconPath ?? avatar.iconPath ?? null,
            iconSource: app.iconSource ?? avatar.iconSource ?? null,
            offByAdmin: !app.offByAdmin,
            productId: app.productId,
            url: app.url ?? null,
            securityManagementUrl: app.securityManagementUrl ?? null,
            securityManagementUrlTemplate: app.securityManagementUrlTemplate ?? null
          } as AdminApplication;
        }
        throw new Error('No application selected');
      }),
      switchMap(payload => this.adminApplicationApiService.postAdminApplications(payload)),
      switchMap(response =>
        this.applicationStateService.currentApplications$.pipe(
          take(1),
          map(apps => {
            const updatedApps = [...apps, response];
            response.offByAdmin = !response.offByAdmin;
            this.applicationStateService.setApplications(
              updatedApps.sort((a, b) => a.applicationName.localeCompare(b.applicationName))
            );
            this.setSelectedAdminApplication(response as AdminApplication);
            this.setSelectedAdminApplicationId(response.applicationPkId);
            this.setIsNew(false);
            this.setPageTitle(response.applicationName);
            this.location.replaceState(`/ag-admin-applications/${response.applicationPkId}`);
            return response;
          })
        )
      )
    );
  }

  deleteClicked() {
    this.applicationStateService.deleteClicked();
  }

  combineProductAndApp(products: Product[], apps: AdminApplication[]) {
    if (products.length === 0 || apps.length === 0) {
      return [];
    }
    const productIds = products.map(product => product.id);
    const matchedApplications = productIds
      .map(productId => {
        return apps.find(app => app.productId === productId);
      })
      .filter(Boolean);
    const partnerApplications = products.map(product => {
      const app = matchedApplications.find(app => app?.productId === product.id);
      return {
        application: app || null,
        partner: product
      };
    });
    return partnerApplications as PartnerApplication[];
  }

  getPortalsApplications() {
    return this.applicationStateService.portalsApplications$;
  }

  setSelectedProduct(product: Product) {
    this.applicationStateService.setSelectedProduct(product);
  }

  getSelectedProduct() {
    return this.applicationStateService.selectedProduct$;
  }

  getClearList() {
    return this.applicationStateService.clearList$;
  }

  setAvatarIcon(data: AvatarIcon) {
    this.applicationStateService.setAvatarIcon(data);
  }

  getAvatarIcon() {
    return this.applicationStateService.avatarIcon$;
  }

  clearList() {
    this.applicationStateService.clearList();
  }

  setIsPartnerDrawerOpen(isOpen: boolean) {
    this.applicationStateService.setIsPartnerDrawerOpen(isOpen);
  }

  getIsProduct() {
    return this.applicationStateService.isProduct$;
  }

  setIsProduct(isProduct: boolean) {
    this.applicationStateService.setIsProduct(isProduct);
  }

  setIconValid(value: boolean) {
    this.applicationStateService.setIconValid(value);
  }

  getIconValid() {
    return this.applicationStateService.isIconValid$;
  }

  getFeatureFlags() {
    return this.applicationStateService.featureFlags$;
  }

  setSelectedAddOption(option: buttonOptionsType) {
    this.applicationStateService.setSelectedAddOption(option);
    this.userService.setSelectedApplicationChange();
    this.userService.setTempUserList([]);
  }

  setPartnerApplications(apps: PartnerApplication[]) {
    this.applicationStateService.setPartnerApplications(apps);
  }
}
