/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { AuthFactor, IdpUser, UserPasswordResetRequest } from '@mri-platform/ag-shared/types';
import { ClientService, SharedApiService, User } from 'libs/ag-shared/services/src/index';
import { Observable, from } from 'rxjs';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class CentralIdentityApiService {
  constructor(
    private sharedApiService: SharedApiService,
    private clientService: ClientService
  ) {}

  getUserFromIdp(Id: string): Observable<IdpUser> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/CentralIdentity/users/${Id}`;
    return from(this.sharedApiService.get(url));
  }

  getUserMfaFactors(Id: string): Observable<AuthFactor[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/CentralIdentity/users/${Id}/factors`;
    return from(this.sharedApiService.get(url));
  }

  resetUserMfaFactors(userId: string): Observable<number> {
    const payload = [{ UserId: userId }];
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/CentralIdentity/users/resetmfa`;
    return from(this.sharedApiService.postNoReturn(url, payload));
  }

  resetUserPassword(userId: string): Observable<number> {
    const payload: UserPasswordResetRequest = {
      userId: userId,
      sendEmail: true,
      federated: true,
      revokeSessions: false
    };

    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/CentralIdentity/users/${userId}/resetpassword/true`;

    return from(this.sharedApiService.postNoReturn(url, payload));
  }

  resetUserAccount(user: User) {
    const payload = [
      {
        UserId: user.id,
        SendEmail: true,
        ForceReset: true
      }
    ];

    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/CentralIdentity/users/reactivate`;
    return from(this.sharedApiService.post(url, payload));
  }

  resendActivationEmail(user: User) {
    const payload = [
      {
        UserId: user.id,
        SendEmail: true,
        ForceReset: false
      }
    ];

    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/CentralIdentity/users/reactivate`;
    return from(this.sharedApiService.post(url, payload));
  }
}
