import { Injectable } from '@angular/core';
import { AppUserAttributes, ClientService, HelloApiService } from '@mri-platform/ag-shared/services';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class HelloService {
  hello = new BehaviorSubject<AppUserAttributes | undefined>(undefined);

  constructor(
    private helloApiService: HelloApiService,
    private clientService: ClientService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.getHello())
      )
      .subscribe(data => {
        if (data) {
          this.hello.next(data);
        }
      });
  }

  getHello(): Observable<AppUserAttributes> {
    return this.helloApiService.getHello();
  }

  getUserInfo(): BehaviorSubject<AppUserAttributes | undefined> {
    return this.hello;
  }
}
