import { NEW_ENTITY_ID } from './common';

export interface UserPasswordResetRequest {
  userId: string | null;
  sendEmail: boolean;
  federated: boolean;
  revokeSessions: boolean;
}

export type IdpUser = {
  ProviderUniqueId: string;
  Active: boolean;
  Username: string;
  FirstName: string;
  LastName: string;
  Email: string;
  SecondaryEmail: string;
  MobilePhone: string;
  MasteredElsewhere: boolean;
  MasteredProviderName: string;
  MasteredProviderType: string;
  Status: AccountState;
  MriClientIds: string[];
};

export type User = {
  userId: string;
  clientId: string;
  isClientAdmin?: boolean;
  clientName: string;
  email: string;
  canSwitchClients?: boolean;
  canChangeAppVisibility?: boolean;
  canAddUserApplication?: boolean;
  logoUri?: string | null;
};

export const createUser = (): User => ({
  userId: `${NEW_ENTITY_ID}`,
  email: 'default@default.default',
  clientId: 'DEFAULT',
  clientName: 'Default Client',
  isClientAdmin: false
});

export type AuthFactor = {
  Id: string;
  Status: AccountState;
  Provider: AuthFactorProvider;
  FactorType: AuthFactorType;
  Profile: AuthFactorProfile;
};

export type AuthFactorProfile = {
  CredentialId: string;
  DeviceType: string;
  Name: string;
  Platform: string;
  Version: string;
  DeviceToken: string;
  PhoneNumber: string;
  PhoneExtension: string;
  Email: string;
};

export enum AuthFactorType {
  EMAIL = 'email',
  CALL = 'call',
  PUSH = 'push',
  QUESTION = 'question',
  SIGNED_NONCE = 'signed_nonce',
  SMS = 'sms',
  HARDWARE_TOKEN = 'token:hardware',
  HOTP = 'token:hotp',
  TOTP = 'token:software:totp',
  TOKEN = 'token',
  U2F = 'u2f',
  WEB = 'web',
  WEBAUTHN = 'webauthn'
}

export enum AuthFactorProvider {
  CUSTOM = 'CUSTOM',
  DUO = 'DUO',
  FIDO = 'FIDO',
  GOOGLE = 'GOOGLE',
  OKTA = 'OKTA',
  RSA = 'RSA',
  SYMANTEC = 'SYMANTEC',
  YUBICO = 'YUBICO'
}

export enum AccountState {
  ACTIVE = 'ACTIVE',
  LOCKED_OUT = 'LOCKED_OUT',
  RECOVERY = 'RECOVERY',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  PROVISIONED = 'PROVISIONED',
  DEPROVISIONED = 'DEPROVISIONED',
  SUSPENDED = 'SUSPENDED',
  STAGED = 'STAGED'
}

type BlockedApplication = {
  sender: string;
};

// @ts-expect-error Unused API Models
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UserSetting = {
  recipient?: string | null;
  email?: string | null;
  dnd?: boolean;
  blockedApplications?: BlockedApplication[] | null;
};

// @ts-expect-error Unused API Models
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UserApplicationAccess = {
  userId?: string | null;
  /** Format: int32 */
  applicationPkId?: number;
  disabled?: boolean;
};
