import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiModule } from '@mri-platform/ag-shared/services';
import { ServicesModule } from './services/services.module';
import { StateModule } from './state/state.module';

@NgModule({
  imports: [CommonModule, StateModule, ApiModule, ServicesModule],
  providers: []
})
export class AdminServicesModule {}
