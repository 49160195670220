import { Injectable, KeyValueDiffers } from '@angular/core';
import {
  AdminApplication,
  AdminApplicationApiService,
  ApplicationUser,
  ClientService,
  createUserApplicationsVisibility,
  isApplicationFieldName,
  parseApplicationIdFromFieldName as parseApplicationPkIdFromFieldName,
  sortAdminApplication,
  toApplicationUser,
  UserApiService,
  UserApplicationsVisibility
} from '@mri-platform/ag-shared/services';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { subtractObjects } from '@mri-platform/shared/core';
import { Dictionary } from '@ngrx/entity';
import { combineLatest, filter, map, switchMap, tap } from 'rxjs';
import { UserApplicationsMatrixStateService } from '../state/user-applications-matrix-state.service';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class UserApplicationsMatrixService {
  constructor(
    private adminApplicationApiService: AdminApplicationApiService,
    private userApiService: UserApiService,
    private userApplicationMatrixStateService: UserApplicationsMatrixStateService,
    private errorPolicy: ErrorPolicyService,
    private differs: KeyValueDiffers,
    private clientService: ClientService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.loadUserApplicationsVisibility()),
        tap(result => {
          this.setUserApplicationsVisibility(result.userAppVisibility);
          this.setApplications(result.sortedApplications);
        })
      )
      .subscribe();

    this.getUserApplicationsVisibilityToUpdate().subscribe(
      (userApplicationsVisibilityToUpdate: UserApplicationsVisibility[]) => {
        this.userApplicationMatrixStateService.updatedUserApplicationVisibilty = userApplicationsVisibilityToUpdate;
      }
    );
  }

  private loadUserApplicationsVisibility() {
    return combineLatest([
      this.userApiService.getUsers(),
      this.adminApplicationApiService.getApplicationUsers(),
      this.adminApplicationApiService.getApplications()
    ]).pipe(
      map(([users, applicationUsers, applications]) => {
        const sortedApplications = applications.sort((a, b) => sortAdminApplication(a, b));
        const userAppVisibility: UserApplicationsVisibility[] = users.map(user => {
          const appUserMap = applicationUsers
            .filter(x => x.userId === user.id)
            .reduce((map, applicationUser) => {
              map[applicationUser.applicationPkId] = applicationUser;
              return map;
            }, {} as Dictionary<ApplicationUser>);

          return createUserApplicationsVisibility(user, sortedApplications, appUserMap);
        });
        return { userAppVisibility, sortedApplications };
      })
    );
  }

  setUserApplicationsVisibility(userApplicationsVisibility: UserApplicationsVisibility[]) {
    this.userApplicationMatrixStateService.userApplicationsVisibilityList = userApplicationsVisibility;
    this.userApplicationMatrixStateService.userApplicationsVisibility.next(userApplicationsVisibility);
  }

  getUserApplicationsVisibility() {
    return this.userApplicationMatrixStateService.userApplicationsVisibility;
  }

  setApplications(applications: AdminApplication[]) {
    this.userApplicationMatrixStateService.applications.next(applications);
  }

  getApplications() {
    return this.userApplicationMatrixStateService.applications;
  }

  getIsCancelled() {
    return this.userApplicationMatrixStateService.onCancelledChanged;
  }

  setIsCancelled(isCancelled: boolean) {
    this.userApplicationMatrixStateService.isCancelled.next(isCancelled);
  }

  getIsSaving() {
    return this.userApplicationMatrixStateService.onIsSavingChanged;
  }

  setIsSaving(isSaving: boolean) {
    this.userApplicationMatrixStateService.isSaving.next(isSaving);
  }

  setUserApplicationsVisibilityToUpdate(userApplicationsVisibility: UserApplicationsVisibility[]) {
    this.userApplicationMatrixStateService.userApplicationsVisibilityToUpdate.next(userApplicationsVisibility);
  }

  getUserApplicationsVisibilityToUpdate() {
    return this.userApplicationMatrixStateService.onUserApplicationsVisibilityToUpdateChanged;
  }

  saveUserApplicationsVisibility() {
    const updatedUserApplicationsVisibility = this.userApplicationMatrixStateService.updatedUserApplicationVisibilty;
    const payload = this.createApplicationUserPayload(updatedUserApplicationsVisibility);
    this.setIsSaving(true);
    this.adminApplicationApiService
      .updateApplicationUsers(payload)
      .pipe(this.errorPolicy.catchHandle())
      .subscribe(() => {
        this.setIsSaving(false);
        this.setUserApplicationsVisibility(updatedUserApplicationsVisibility);
      });
  }

  private createApplicationUserPayload(updates: UserApplicationsVisibility[]): ApplicationUser[] {
    const original = this.userApplicationMatrixStateService.userApplicationsVisibilityList;
    const diffs = subtractObjects(updates, original, 'userId', this.differs);

    const changes = Object.entries(diffs).flatMap(([userId, diff]) =>
      Object.entries(diff)
        .filter(([fieldName]) => isApplicationFieldName(fieldName))
        .map(([fieldName, show]) => ({
          applicationPkId: parseApplicationPkIdFromFieldName(fieldName),
          userId,
          disabled: !show,
          show: !!show
        }))
    );

    const disabled = changes
      .filter(c => !c.show)
      .map(toApplicationUser)
      .map(appUser => ({ ...appUser, disabled: true }));
    const enabled = changes
      .filter(c => c.show)
      .map(toApplicationUser)
      .map(appUser => ({ ...appUser, disabled: false }));

    return [...disabled, ...enabled];
  }
}
