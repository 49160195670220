import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AdminApplication,
  AdminApplicationApiService,
  ApiFeatureFlag,
  buttonOptionsType,
  ClientApplicationType,
  ClientService,
  FeaturesApiService,
  PartnerApplication,
  Product
} from '@mri-platform/ag-shared/services';
import { AvatarIcon } from '@mri-platform/shared/common-types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ButtonProgressState } from '@mri-platform/ui-buttons';
import { BehaviorSubject, filter, Subject, switchMap, take, tap } from 'rxjs';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class ApplicationStateService {
  private iconSelectorOpenSubject = new BehaviorSubject<boolean>(false);
  iconSelectorOpen$ = this.iconSelectorOpenSubject.asObservable();

  private isNewSubject = new BehaviorSubject<boolean>(false);
  isNew$ = this.isNewSubject.asObservable();

  private modelSubject = new BehaviorSubject<AdminApplication>({} as AdminApplication);
  model$ = this.modelSubject.asObservable();

  private clientAppTypeSubject = new BehaviorSubject<ClientApplicationType | null>(null);
  clientAppType$ = this.clientAppTypeSubject.asObservable();

  private isClientDefinedSubject = new BehaviorSubject<boolean>(false);
  isClientDefined$ = this.isClientDefinedSubject.asObservable();

  private isPortalSubject = new BehaviorSubject<boolean>(false);
  isPortal$ = this.isPortalSubject.asObservable();

  private isEditableSubject = new BehaviorSubject<boolean>(true);
  isEditable$ = this.isEditableSubject.asObservable();

  private pageTitleSubject = new BehaviorSubject<string>('');
  pageTitle$ = this.pageTitleSubject.asObservable();

  private nameLabelSubject = new BehaviorSubject<string>('Name');
  nameLabel$ = this.nameLabelSubject.asObservable();

  private descriptionLabelSubject = new BehaviorSubject<string>('Description');
  descriptionLabel$ = this.descriptionLabelSubject.asObservable();

  private isPartnerSubject = new BehaviorSubject<boolean>(false);
  isPartner$ = this.isPartnerSubject.asObservable();

  private showProductDropdownSubject = new BehaviorSubject<boolean>(false);
  showProductDropdown$ = this.showProductDropdownSubject.asObservable();

  private appNameLabelSubject = new BehaviorSubject<string>('');
  appNameLabel$ = this.appNameLabelSubject.asObservable();

  private isPartnerDrawerOpenSubject = new BehaviorSubject<boolean>(false);
  isPartnerDrawerOpen$ = this.isPartnerDrawerOpenSubject;

  private isSelfHostedSubject = new BehaviorSubject<boolean>(false);
  isSelfHosted$ = this.isSelfHostedSubject.asObservable();

  private canCancelSubject = new BehaviorSubject<boolean>(false);
  canCancel$ = this.canCancelSubject.asObservable();

  private canDeleteSubject = new BehaviorSubject<boolean>(false);
  canDelete$ = this.canDeleteSubject.asObservable();

  private canSaveSubject = new BehaviorSubject<boolean>(false);
  canSave$ = this.canSaveSubject.asObservable();

  private showDeleteSubject = new BehaviorSubject<boolean>(false);
  showDelete$ = this.showDeleteSubject.asObservable();

  private deleteProgressStateSubject = new BehaviorSubject<ButtonProgressState | undefined>(undefined);
  deleteProgressState$ = this.deleteProgressStateSubject.asObservable();

  private saveProgressStateSubject = new BehaviorSubject<ButtonProgressState | undefined>(undefined);
  saveProgressState$ = this.saveProgressStateSubject.asObservable();

  private currentApplicationsSubject = new BehaviorSubject<AdminApplication[]>([]);
  currentApplications$ = this.currentApplicationsSubject.asObservable();

  private selectedAdminApplicationIdSubject = new BehaviorSubject<number>(0);
  selectedAdminApplicationId$ = this.selectedAdminApplicationIdSubject.asObservable();

  private selectedAdminApplicationSubject = new BehaviorSubject<AdminApplication | undefined>(undefined);
  selectedAdminApplication$ = this.selectedAdminApplicationSubject.asObservable();

  private cancelSubject = new BehaviorSubject<boolean>(false);
  cancel$ = this.cancelSubject.asObservable();

  private deleteSubject = new Subject<void>();
  delete$ = this.deleteSubject.asObservable();

  public isDirtySubject = new BehaviorSubject<boolean>(false);
  isDirty$ = this.isDirtySubject.asObservable();

  private isValidSubject = new BehaviorSubject<boolean>(false);
  isValid$ = this.isValidSubject.asObservable();

  private saveSubject = new BehaviorSubject<boolean>(false);
  save$ = this.saveSubject.asObservable();

  private getNewDetailsSubject = new BehaviorSubject<boolean>(false);
  getNewDetails$ = this.getNewDetailsSubject.asObservable();

  private hasSelfHostedSubject = new BehaviorSubject<boolean>(false);
  hasSelfHosted$ = this.hasSelfHostedSubject;

  private selectedProductSubject = new BehaviorSubject<Product | undefined>(undefined);
  selectedProduct$ = this.selectedProductSubject;

  private clearListSubject = new Subject<void>();
  clearList$ = this.clearListSubject.asObservable();

  private avatarIconSubject = new BehaviorSubject<AvatarIcon>({} as AvatarIcon);
  avatarIcon$ = this.avatarIconSubject.asObservable();

  private partnerApplicationsSubject = new BehaviorSubject<PartnerApplication[]>([]);
  partnerApplications$ = this.partnerApplicationsSubject.asObservable();

  private portalsApplicationsSubject = new BehaviorSubject<PartnerApplication[]>([]);
  portalsApplications$ = this.portalsApplicationsSubject.asObservable();

  private isProductSubject = new BehaviorSubject<boolean>(false);
  isProduct$ = this.isProductSubject.asObservable();

  private featureFlagsSubject = new BehaviorSubject<ApiFeatureFlag[]>([]);
  featureFlags$ = this.featureFlagsSubject.asObservable();

  private isIconValidSubject = new BehaviorSubject<boolean>(false);
  isIconValid$ = this.isIconValidSubject.asObservable();

  private showSecurityManagementUrlSubject = new BehaviorSubject<boolean>(false);
  shouldShowSecurityManagementUrl$ = this.showSecurityManagementUrlSubject.asObservable();

  private showDefaultAppField = new BehaviorSubject<boolean>(true);
  showDefaultAppField$ = this.showDefaultAppField.asObservable();

  constructor(
    private applicationApiService: AdminApplicationApiService,
    private location: Location,
    private clientService: ClientService,
    private featuresApiService: FeaturesApiService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.applicationApiService.getApplications()),
        tap(apps => {
          apps.sort((a, b) => a.applicationName.localeCompare(b.applicationName));
          apps.forEach(app => {
            app.offByAdmin = !app.offByAdmin;
          });
          this.currentApplicationsSubject.next(apps);
        }),
        switchMap(() => this.featuresApiService.getFeatureFlags()),
        tap(flags => this.featureFlagsSubject.next(flags))
      )
      .subscribe();
  }

  //Can improve this by turning into object
  setSelectedAddOption(type: buttonOptionsType) {
    this.setSelectedAdminApplication({ iconBackgroundColor: '#778692', offByAdmin: true } as AdminApplication);
    this.setIsNew(true);
    this.setIsPortal(false);
    this.setIsPartner(false);
    this.setIsEditable(true);
    this.setShowProductDropdown(false);
    this.setShowDelete(false);
    this.setIsSelfHosted(false);
    this.setIsClientDefined(true);
    this.clearList();
    switch (type) {
      case buttonOptionsType.clientDefined:
        this.setNameLabel('Name');
        this.setDescriptionLabel('Description');
        this.setPageTitle('Add client-defined application');
        this.setClientAppType('ClientDefined');
        this.setIsValid(false);
        this.setShowDefaultAppField(true);
        this.setShowSecurityManagementUrl(false);
        this.location.replaceState('/admin/ag-admin-applications/-1?clientAppType=ClientDefined');
        break;
      case buttonOptionsType.portal:
        this.setNameLabel('Portal Type');
        this.setDescriptionLabel('Portal Name');
        this.setIsPortal(true);
        this.setPageTitle('Add portal application');
        this.setShowProductDropdown(true);
        this.setClientAppType('Portal');
        this.setIsClientDefined(false);
        this.setIsProduct(true);
        this.setShowDefaultAppField(true);
        this.setShowSecurityManagementUrl(true);
        this.location.replaceState('/admin/ag-admin-applications/-1?clientAppType=Portal');
        break;
      case buttonOptionsType.selfHosted:
        this.setNameLabel('Application');
        this.setDescriptionLabel('Description');
        this.setPageTitle('Add self-hosted application');
        this.setClientAppType('SelfHosted');
        this.setIsSelfHosted(true);
        this.setShowProductDropdown(true);
        this.setIsClientDefined(false);
        this.setIsProduct(true);
        this.setShowSecurityManagementUrl(false);
        this.setShowDefaultAppField(false);
        this.location.replaceState('/admin/ag-admin-applications/-1?clientAppType=SelfHosted');
        break;
      case buttonOptionsType.partner:
        this.setIsPartnerDrawerOpen(true);
        this.setPageTitle('Add partner application');
        this.setIsClientDefined(false);
        this.setShowSecurityManagementUrl(true);
        this.setShowDefaultAppField(true);
        break;
    }
  }

  setIconSelectorOpen(value: boolean) {
    this.iconSelectorOpenSubject.next(value);
  }

  setIsNew(isNew: boolean) {
    this.isNewSubject.next(isNew);
  }

  setModel(model: AdminApplication) {
    this.modelSubject.next(model);
  }

  setClientAppType(cat: ClientApplicationType | null) {
    this.clientAppTypeSubject.next(cat);
  }

  setIsClientDefined(value: boolean) {
    this.isClientDefinedSubject.next(value);
  }

  setIsPortal(value: boolean) {
    this.isPortalSubject.next(value);
  }

  setIsEditable(value: boolean) {
    this.isEditableSubject.next(value);
  }

  setNameLabel(value: string) {
    this.nameLabelSubject.next(value);
  }

  setDescriptionLabel(value: string) {
    this.descriptionLabelSubject.next(value);
  }

  setIsPartner(value: boolean) {
    this.isPartnerSubject.next(value);
  }

  setShowProductDropdown(value: boolean) {
    this.showProductDropdownSubject.next(value);
  }

  setAppNameLabel(value: string) {
    this.appNameLabelSubject.next(value);
  }

  setIsPartnerDrawerOpen(value: boolean) {
    this.isPartnerDrawerOpenSubject.next(value);
  }

  setPartnerApplications(value: PartnerApplication[]) {
    this.partnerApplicationsSubject.next(value);
  }

  setPageTitle(value: string) {
    this.pageTitleSubject.next(value);
  }

  setIsSelfHosted(value: boolean) {
    this.isSelfHostedSubject.next(value);
  }

  setSelectedAdminApplication(app?: AdminApplication) {
    this.selectedAdminApplicationSubject.next(app);
  }

  setShowDelete(value: boolean) {
    this.showDeleteSubject.next(value);
  }

  setCanDelete(value: boolean) {
    this.canDeleteSubject.next(value);
  }

  setCanCancel(value: boolean) {
    this.canCancelSubject.next(value);
  }

  getCancelClicked() {
    return this.cancel$;
  }

  getDeleteClicked() {
    return this.delete$;
  }

  setDeleteClicked() {
    this.deleteSubject.next();
  }

  setCancelClicked() {
    this.cancelSubject.next(true);
  }

  setIsDirty(value: boolean) {
    this.isDirtySubject.next(value);
  }

  setIsValid(value: boolean) {
    this.isValidSubject.next(value);
  }

  setShowSecurityManagementUrl(value: boolean) {
    this.showSecurityManagementUrlSubject.next(value);
  }

  setShowDefaultAppField(value: boolean) {
    this.showDefaultAppField.next(value);
  }

  getSaveClicked() {
    return this.save$;
  }

  getToNewAppDetails() {
    return this.getNewDetails$;
  }

  setSaveClicked() {
    this.saveSubject.next(true);
  }

  setToNewAppDetails() {
    this.getNewDetailsSubject.next(true);
  }

  setSaveState(value: ButtonProgressState) {
    this.saveProgressStateSubject.next(value);
  }

  setDeleteState(value: ButtonProgressState) {
    this.deleteProgressStateSubject.next(value);
  }

  setApplications(apps: AdminApplication[]) {
    this.currentApplicationsSubject.next(apps);
  }

  deleteClicked() {
    this.selectedAdminApplication$.pipe(take(1)).subscribe(app => {
      if (!app) return;
      this.applicationApiService.deleteAdminApplication(app.applicationPkId.toString());
      this.currentApplications$.pipe(take(1)).subscribe(apps => {
        const index = apps.findIndex(a => a.applicationPkId === app.applicationPkId);
        apps.splice(index, 1);
        const newApps = [...apps];
        this.setApplications(newApps);
      });
      this.setDeleteState({ started: false, errored: false, succeeded: true });
    });
    this.deleteSubject.next();
  }

  setSelectedProduct(product: Product) {
    this.selectedProductSubject.next(product);
  }

  clearList() {
    this.clearListSubject.next();
  }

  setAvatarIcon(value: AvatarIcon) {
    this.avatarIconSubject.next(value);
  }

  setIsProduct(value: boolean) {
    this.isProductSubject.next(value);
  }

  setIconValid(value: boolean) {
    this.isIconValidSubject.next(value);
  }
}
