import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class LoadingService {
  private showLoadingSubject = new BehaviorSubject<boolean>(true);
  showLoading$ = this.showLoadingSubject.asObservable();

  getShowLoading() {
    return this.showLoading$;
  }

  setShowLoading(value: boolean) {
    this.showLoadingSubject.next(value);
  }
}
