import { Injectable } from '@angular/core';
import { buttonOptions, ClientService, Product, ProductApiService } from '@mri-platform/ag-shared/services';
import { EntityList } from '@mri-platform/shared/entity';
import { BehaviorSubject, filter, map, switchMap } from 'rxjs';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class ProductStateService {
  private portalsProductSubject = new BehaviorSubject<Product[]>([]);
  portalsProduct$ = this.portalsProductSubject.asObservable();

  private partnerProductsSubject = new BehaviorSubject<Product[]>([]);
  partnerProduct$ = this.partnerProductsSubject.asObservable();

  private addOptionsSubject = new BehaviorSubject<buttonOptions[]>([]);
  addOptions$ = this.addOptionsSubject.asObservable();

  private selfHostedApplicationsSubject = new BehaviorSubject<Product[]>([]);
  selfHostedApplications$ = this.selfHostedApplicationsSubject;

  private selfHostedSubject = new BehaviorSubject<EntityList<Product>>({} as EntityList<Product>);
  selfHosted$ = this.selfHostedSubject.asObservable();

  private productsSubject = new BehaviorSubject<Product[]>([]);
  products$ = this.productsSubject.asObservable();

  constructor(
    private productApiService: ProductApiService,
    private clientService: ClientService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.productApiService.getPortalsProduct()),
        map(portalsProduct => this.portalsProductSubject.next(portalsProduct)),
        switchMap(() => this.productApiService.getPartnerProducts()),
        map(products => this.partnerProductsSubject.next(products))
      )
      .subscribe();
  }

  setAddAppOptions(value: buttonOptions[]) {
    this.addOptionsSubject.next(value);
  }

  setSelfHostedApplications(value: Product[]) {
    this.selfHostedApplicationsSubject.next(value);
  }

  setSelfHosted(selfHosted: EntityList<Product>) {
    this.selfHostedSubject.next(selfHosted);
  }
}
