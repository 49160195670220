import { Injectable } from '@angular/core';
import { ClientService, User, UserApiService } from '@mri-platform/ag-shared/services';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { filter, switchMap, tap } from 'rxjs';
import { UserManagementStateService } from '../state/user-management-state.service';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class UserManagementService {
  constructor(
    private userApiService: UserApiService,
    private userManagementStateService: UserManagementStateService,
    private errorPolicy: ErrorPolicyService,
    private clientService: ClientService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.loadUsers())
      )
      .subscribe();

    this.getUsersToUpdate().subscribe(users => {
      this.userManagementStateService.updatedUsers = users;
    });
  }

  getUsersToUpdate() {
    return this.userManagementStateService.onUsersToUpdateChanged;
  }

  setUsersToUpdate(users: User[]) {
    this.userManagementStateService.usersToUpdate.next(users);
  }

  getIsSaving() {
    return this.userManagementStateService.onIsSavingChanged;
  }

  setIsSaving(isSaving: boolean) {
    this.userManagementStateService.isSaving.next(isSaving);
  }

  getIsCancelled() {
    return this.userManagementStateService.onCancelledChanged;
  }

  setIsCancelled(isCancelled: boolean) {
    this.userManagementStateService.isCancelled.next(isCancelled);
  }

  setUsers(users: User[]) {
    this.userManagementStateService.users.next(users);
  }

  getUsers() {
    return this.userManagementStateService.users;
  }

  private loadUsers() {
    return this.userApiService.getUsers().pipe(
      tap(users => {
        this.setUsers(users);
      })
    );
  }

  saveUsers() {
    this.setIsSaving(true);
    this.userApiService
      .updateUsers(this.userManagementStateService.updatedUsers)
      .pipe(this.errorPolicy.catchHandle())
      .subscribe(() => {
        this.setIsSaving(false);
        this.setUsers(this.userManagementStateService.updatedUsers);
      });
  }
}
