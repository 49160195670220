import { Injectable } from '@angular/core';
import { User } from '@mri-platform/ag-shared/services';
import { ReplaySubject } from 'rxjs';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class UserManagementStateService {
  users = new ReplaySubject<User[]>();
  usersToUpdate = new ReplaySubject<User[]>();
  onUsersToUpdateChanged = this.usersToUpdate.asObservable();
  updatedUsers: User[] = [];
  isSaving = new ReplaySubject<boolean>();
  onIsSavingChanged = this.isSaving.asObservable();
  isCancelled = new ReplaySubject<boolean>();
  onCancelledChanged = this.isCancelled.asObservable();
}
