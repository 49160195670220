import { Injectable } from '@angular/core';
import { AccountState, AuthFactor, IdpUser } from '@mri-platform/ag-shared/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class UserAccountService {
  private currentUserMfasSubject = new BehaviorSubject<AuthFactor[] | null>(null);
  currentUserMfas$ = this.currentUserMfasSubject.asObservable();

  private currentIdpUserSubject = new BehaviorSubject<IdpUser | null>(null);
  currentIdpUser$ = this.currentIdpUserSubject.asObservable();

  private isPageLoadingSubject = new BehaviorSubject<boolean>(false);
  isPageLoading$ = this.isPageLoadingSubject.asObservable();

  private isMfaResetButtonDisabledSubject = new BehaviorSubject<boolean>(false);
  isMfaResetButtonDisabled$ = this.isMfaResetButtonDisabledSubject.asObservable();

  private isAccountResetButtonDisabledSubject = new BehaviorSubject<boolean>(false);
  isAccountResetButtonDisabled$ = this.isAccountResetButtonDisabledSubject.asObservable();

  private isPasswordResetDisabledSubject = new BehaviorSubject<boolean>(false);
  isPasswordResetDisabled$ = this.isPasswordResetDisabledSubject.asObservable();

  private mfaResetLoadingSubject = new BehaviorSubject<boolean>(true);
  mfaResetLoading$ = this.mfaResetLoadingSubject.asObservable();

  private userAccountResetLoadingSubject = new BehaviorSubject<boolean>(true);
  userAccountResetLoading$ = this.userAccountResetLoadingSubject.asObservable();

  private userPasswordResetLoadingSubject = new BehaviorSubject<boolean>(true);
  userPasswordResetLoading$ = this.userPasswordResetLoadingSubject.asObservable();

  allowedAccountStatesForPasswordReset: AccountState[] = [
    AccountState.ACTIVE,
    AccountState.LOCKED_OUT,
    AccountState.RECOVERY,
    AccountState.PASSWORD_EXPIRED
  ];
  allowedAccountStatesForMfaReset: AccountState[] = [
    AccountState.ACTIVE,
    AccountState.RECOVERY,
    AccountState.PASSWORD_EXPIRED,
    AccountState.PROVISIONED,
    AccountState.LOCKED_OUT
  ];
  allowedAccountStatesForAccountReset: AccountState[] = [
    AccountState.ACTIVE,
    AccountState.RECOVERY,
    AccountState.PASSWORD_EXPIRED,
    AccountState.DEPROVISIONED,
    AccountState.SUSPENDED,
    AccountState.LOCKED_OUT,
    AccountState.STAGED,
    AccountState.PROVISIONED
  ];

  getCurrentUserMfas(): Observable<AuthFactor[] | null> {
    return this.currentUserMfas$;
  }

  setCurrentUserMfas(value: AuthFactor[] | null): void {
    this.currentUserMfasSubject.next(value);
  }

  getCurrentIdpUser(): Observable<IdpUser | null> {
    return this.currentIdpUser$;
  }

  setCurrentIdpUser(value: IdpUser | null): void {
    this.currentIdpUserSubject.next(value);
  }

  getIsPageLoading(): Observable<boolean> {
    return this.isPageLoading$;
  }

  setIsPageLoading(value: boolean): void {
    this.isPageLoadingSubject.next(value);
  }

  getIsMfaResetButtonDisabled(): Observable<boolean> {
    return this.isMfaResetButtonDisabled$;
  }

  setIsMfaResetButtonDisabled(value: boolean): void {
    this.isMfaResetButtonDisabledSubject.next(value);
  }

  getIsAccountResetButtonDisabled(): Observable<boolean> {
    return this.isAccountResetButtonDisabled$;
  }

  setIsAccountResetButtonDisabled(value: boolean): void {
    this.isAccountResetButtonDisabledSubject.next(value);
  }

  getIsPasswordResetDisabled(): Observable<boolean> {
    return this.isPasswordResetDisabled$;
  }

  setIsPasswordResetDisabled(value: boolean): void {
    this.isPasswordResetDisabledSubject.next(value);
  }

  getMfaResetLoading(): Observable<boolean> {
    return this.mfaResetLoading$;
  }

  setMfaResetLoading(value: boolean): void {
    this.mfaResetLoadingSubject.next(value);
  }

  getUserAccountResetLoading(): Observable<boolean> {
    return this.userAccountResetLoading$;
  }

  setUserAccountResetLoading(value: boolean): void {
    this.userAccountResetLoadingSubject.next(value);
  }

  getUserPasswordResetLoading(): Observable<boolean> {
    return this.userPasswordResetLoading$;
  }

  setUserPasswordResetLoading(value: boolean): void {
    this.userPasswordResetLoadingSubject.next(value);
  }
}
