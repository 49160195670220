import { Injectable } from '@angular/core';
import {
  AdminApplication,
  AdminApplicationApiService,
  ApplicationUser,
  ApplicationUserVisibility,
  ClientService,
  User,
  UserApiService
} from '@mri-platform/ag-shared/services';
import { Dictionary } from '@ngrx/entity';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  from,
  map,
  Observable,
  switchMap,
  tap,
  toArray
} from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { ApplicationStateService } from './application-state.service';
import { StateModule } from './state.module';

@Injectable({
  providedIn: StateModule
})
export class UserStateService {
  private currentAdminUsersSubject = new BehaviorSubject<User[]>([]);
  currentAdminUsers$ = this.currentAdminUsersSubject.asObservable();

  private selectedAdminUsersSubject = new BehaviorSubject<string[]>([]);
  selectedAdminUsers$ = this.selectedAdminUsersSubject.asObservable();

  private userList = new BehaviorSubject<ApplicationUserVisibility[]>([]);
  userList$ = this.userList.asObservable();

  private tempUserList = new BehaviorSubject<ApplicationUserVisibility[]>([]);
  tempUserList$ = this.tempUserList.asObservable();

  private currentApplicationUsersSubject = new BehaviorSubject<ApplicationUser[]>([]);
  currentApplicationUsers$ = this.currentApplicationUsersSubject.asObservable();

  private currentApplicationUsersDictionarySubject = new BehaviorSubject<Dictionary<ApplicationUser>>({});
  currentApplicationUsersDictionary$ = this.currentApplicationUsersDictionarySubject.asObservable();

  private selectedApplicationChange = new BehaviorSubject<boolean>(false);
  selectedApplicationChange$ = this.selectedApplicationChange.asObservable();

  private adminUsers!: User[];
  private applicationUsers!: ApplicationUser[];
  private adminApplication!: AdminApplication;
  constructor(
    private applicationStateService: ApplicationStateService,
    private applicationApiService: AdminApplicationApiService,
    private userApiService: UserApiService,
    private clientService: ClientService,
    private loadingService: LoadingService
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => clientId !== undefined),
        switchMap(() => this.userApiService.getUsers()),
        map(users => {
          this.currentAdminUsersSubject.next(users);
          this.adminUsers = users;
        }),
        switchMap(() => this.applicationApiService.getApplicationUsers()),
        map(users => {
          this.currentApplicationUsersSubject.next(users);
          this.applicationUsers = users;
          const dictionary: Dictionary<ApplicationUser> = {};
          users.forEach(user => {
            dictionary[user.userId] = user;
          });
          this.currentApplicationUsersDictionarySubject.next(dictionary);
        }),
        switchMap(() => this.selectedApplicationChange$),
        switchMap(() =>
          from(this.adminUsers).pipe(
            map(adminUser => {
              return this.createApplicationUserVisibility(adminUser, this.adminApplication, this.applicationUsers);
            }),
            toArray()
          )
        ),
        tap(applicationUserVisibilityList => {
          this.setUserList(applicationUserVisibilityList);
          this.loadingService.setShowLoading(false);
        }),
        catchError(error => {
          this.loadingService.setShowLoading(false);
          return error;
        })
      )
      .subscribe();

    this.applicationStateService.selectedAdminApplication$.subscribe(selectedAdminApp => {
      if (selectedAdminApp === undefined) return;
      this.adminApplication = selectedAdminApp;
    });
  }

  pushToApplicationUsers(values: ApplicationUser) {
    const index = this.applicationUsers.findIndex(
      user => user.userId === values.userId && user.applicationPkId === values.applicationPkId
    );
    if (index !== -1) {
      this.applicationUsers[index] = values;
    }
  }

  getUserList(): Observable<ApplicationUserVisibility[]> {
    return this.userList$;
  }

  setUserList(users: ApplicationUserVisibility[]): void {
    this.userList.next(users);
  }

  getTempUserList(): Observable<ApplicationUserVisibility[]> {
    return this.tempUserList$;
  }

  setTempUserList(users: ApplicationUserVisibility[]): void {
    this.tempUserList.next(users);
  }

  getSelectedAdminUsers(): Observable<string[]> {
    return this.selectedAdminUsers$;
  }

  getUsersWithMatchingIds() {
    return combineLatest([this.currentAdminUsers$, this.currentApplicationUsers$]).pipe(
      map(([currentAdminUsers, applicationUsers]) => {
        const applicationUserIds = new Set(applicationUsers.map(user => user.userId));
        return currentAdminUsers.filter(user => applicationUserIds.has(user.id)).map(user => user.id);
      })
    );
  }

  setSelectedAdminUsers(userIds: string[]): void {
    this.selectedAdminUsersSubject.next(userIds);
  }

  getSpecificUsers(users: ApplicationUserVisibility[]) {
    const userIdsToFind = users.map(userWithStatus => userWithStatus.userId);
    return this.currentAdminUsers$.pipe(map(users => users.filter(user => userIdsToFind.includes(user.id))));
  }

  isApplicationVisibleToUser(currentApplicationUsers: ApplicationUser[]) {
    return (application: AdminApplication, userId: string) => {
      if (application === undefined) return false;
      const appId = application.applicationPkId;
      const applicationUser = currentApplicationUsers.find(
        user => user.applicationPkId === appId && user.userId === userId
      );
      return applicationUser === undefined ? application.offByAdmin : !applicationUser.disabled;
    };
  }

  createApplicationUserVisibility(
    user: User,
    application: AdminApplication,
    currentApplicationUsers: ApplicationUser[]
  ): ApplicationUserVisibility {
    if (application === undefined) return {} as ApplicationUserVisibility;
    if (currentApplicationUsers === undefined) return {} as ApplicationUserVisibility;
    const isVisible = this.isApplicationVisibleToUser(currentApplicationUsers);
    const { givenName, familyName, email, id: userId } = user;
    const show = isVisible(application, user.id);

    return {
      applicationPkId: AdminApplication.selectIdAs<number>(application),
      givenName,
      familyName,
      email,
      userId,
      disabled: !show,
      show: show
    };
  }

  setCurrentApplicationUsers(users: ApplicationUser[]): void {
    this.currentApplicationUsersSubject.next(users);
  }

  setSelectedApplicationChange(): void {
    this.selectedApplicationChange.next(true);
  }
}
