import { Injectable } from '@angular/core';
import {
  AdminApplication,
  AdminApplicationApiService,
  ApplicationUser,
  ApplicationUserVisibility
} from '@mri-platform/ag-shared/services';
import { concatMap, map, Observable, tap } from 'rxjs';
import { UserStateService } from '../state/user-state.service';
import { ServicesModule } from './services.module';

@Injectable({
  providedIn: ServicesModule
})
export class UserService {
  constructor(
    private userStateService: UserStateService,
    private adminApplicationApiService: AdminApplicationApiService
  ) {}

  setUserList(users: ApplicationUserVisibility[]): void {
    this.userStateService.setUserList(users);
  }

  getUserList(): Observable<ApplicationUserVisibility[]> {
    return this.userStateService.getUserList();
  }

  setTempUserList(users: ApplicationUserVisibility[]): void {
    this.userStateService.setTempUserList(users);
  }

  getTempUserList(): Observable<ApplicationUserVisibility[]> {
    return this.userStateService.getTempUserList();
  }

  getSelectedAdminUsers(): Observable<string[]> {
    return this.userStateService.getSelectedAdminUsers();
  }

  setSelectedAdminUsers(userIds: string[]): void {
    this.userStateService.setSelectedAdminUsers(userIds);
  }

  postAdminUsers(app: AdminApplication | undefined) {
    return this.userStateService.getTempUserList().pipe(
      map(users =>
        users.map(
          user =>
            ({
              userId: user.userId,
              applicationPkId: app?.applicationPkId,
              disabled: !user.disabled
            }) as ApplicationUser
        )
      ),
      tap(users => {
        this.userStateService.setCurrentApplicationUsers(users);
        users.forEach(this.pushToApplicationUsers.bind(this));
      }),
      concatMap(users => this.adminApplicationApiService.updateApplicationUsers(users))
    );
  }

  getAdminUsers() {
    return this.userStateService.currentAdminUsers$;
  }

  getUsersWithMatchingIds() {
    return this.userStateService.getUsersWithMatchingIds();
  }

  setSelectedApplicationChange() {
    this.userStateService.setSelectedApplicationChange();
  }

  pushToApplicationUsers(values: ApplicationUser) {
    this.userStateService.pushToApplicationUsers(values);
  }
}
